import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getAppSettings() {
  try {
    const accessToken = localStorage.getItem("accessToken");
    // if (isValidToken(accessToken)) {
    const response = await axios({
      method: "get",
      url: `lms_chat/api/app_setup`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const res = response.data;
    return res;
    // }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
