// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

// Create Context
export const CannedResponsesContext = createContext();

// Initial State
const initialState = {
  cannedResponses: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { cannedResponses } = action.payload;
    return { ...state, cannedResponses };
  },
  //   UPDATE_BROADCAST: (state, action) => {
  //     const { broadcasts, journeyId } = action.payload;
  //     const { journeys } = state;
  //     journeys.forEach((journey) => {
  //       if (journey.journey_id === journeyId) {
  //         journey.broadcasts = broadcasts;
  //       }
  //     });
  //     return { ...state, journeys };
  //   },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Provider Component
export const CannedResponsesContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCannedResponses = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `/lms_user/api/gmb/reviews/canned_response`,
      });
      const { canned_responses } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          cannedResponses: canned_responses,
        },
      });
      // }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  const addResponse = async (rating, responseText) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `/lms_user/api/gmb/reviews/canned_response`,
        params: { rating, response: responseText },
      });
      const { canned_responses } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          cannedResponses: canned_responses,
        },
      });
      // }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  const updateResponse = async (rating, responseText, responseId) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `/lms_user/api/gmb/reviews/canned_response/${responseId}`,
        params: { rating, response: responseText, _method: "patch" },
      });
      const { canned_responses } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          cannedResponses: canned_responses,
        },
      });
      // }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  const deleteResponse = async (responseId) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `/lms_user/api/gmb/reviews/canned_response/${responseId}`,
        params: { _method: "delete" },
      });
      const { canned_responses } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          cannedResponses: canned_responses,
        },
      });
      // }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  useEffect(() => getCannedResponses(), []);

  return (
    <CannedResponsesContext.Provider
      value={{
        cannedResponses: state.cannedResponses,
        addResponse,
        updateResponse,
        deleteResponse,
      }}
    >
      <Outlet />
    </CannedResponsesContext.Provider>
  );
};
