// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axiosInstance from "src/utils/axios";
import { isValidToken } from "../../utils/jwt";

// Initial State
const initialState = {
  brand: null,
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { brand } = action.payload;
    return {
      ...state,
      brand,
      loading: false,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const ChatSettingsContext = createContext();

// Provider Component
export const ChatSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Auth state
  // const { isAuthenticated, isInitialized } = useAuth();

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axiosInstance.get(
        "lms_chat/api/business_messages/brand",
        { signal: controller.signal },
        {
          // headers: {
          //   "Access-Control-Allow-Origin": "*",
          //   Authorization: `Bearer ${accessToken}`,
          // },
          headers: {
            "x-user-id": localStorage.getItem("_id"),
          },
        }
      );
      const { brand } = await response.data;
      localStorage.setItem("brandID", brand?.brand_id);
      // cancel the request
      controller.abort();
      dispatch({
        type: "INITIALIZE",
        payload: {
          brand,
        },
      });
      // } else {
      //   dispatch({
      //     type: "INITIALIZE",
      //     payload: {
      //       brand: null,
      //     },
      //   });
      // }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          brand: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const updateBrandId = async (brandData) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `/lms_chat/api/business_messages/brand`,
        headers: {
          "x-user-id": localStorage.getItem("_id"),
        },
        params: { brand_id: brandData.brandID },
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateAgentId = async (agentData) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `/lms_chat/api/business_messages/agent`,
        headers: {
          "x-user-id": localStorage.getItem("_id"),
        },
        params: { agent_id: agentData.agentID },
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <ChatSettingsContext.Provider
      value={{
        brand: state.brand,
        loading: state.loading,
        updateBrandId,
        updateAgentId,
      }}
    >
      <Outlet />
      {/* {children} */}
    </ChatSettingsContext.Provider>
  );
};
