import { Container, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import Page from "../../components/Page";

import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import { useSnackbar } from "notistack";

import { PATH_DASHBOARD } from "src/routes/paths";

import createThirdPartyConnectors from "src/services/whatsApp/trirdpartyconnectors/createThirdPartyConnectors";
import { useNavigate } from "react-router";

export default function CreateThirdPartyConnector() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false); // For general loading state
  const [thirdPartyConnectors, setThirdPartyConnectors] = useState("");

  const navigate = useNavigate();

  //   Handle the update button click
  const createConnector = async () => {
    try {
      setLoading(true); // Set button loading state
      await createThirdPartyConnectors(thirdPartyConnectors);
      enqueueSnackbar("Third Party Connector created successfully", {
        variant: "success",
      });
      navigate(PATH_DASHBOARD.whatsapp.thridpartyConnectors);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error}`, {
        variant: "error",
      });
    } finally {
      setLoading(false); // Reset button loading state
    }
  };

  return (
    <Page title="WhatsApp: Create Third Party Connectors">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          sx={{ maxWidth: "1000px" }}
          heading="Create Third Party Connectors"
          links={[
            { name: "WhatsApp" },
            {
              name: "Third Party Connectors",
              href: PATH_DASHBOARD.whatsapp.thridpartyConnectors,
            },
            { name: "Create Third Party Connector" },
          ]}
        />

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ maxWidth: 600 }}
        >
          <TextField
            fullWidth
            id="outlined-controlled"
            label="Third Party Connectors name"
            value={thirdPartyConnectors}
            onChange={(event) => setThirdPartyConnectors(event.target.value)}
          />

          <LoadingButton
            size="large"
            onClick={createConnector}
            loading={loading} // Set button loading state
            variant="contained"
          >
            Create
          </LoadingButton>
        </Stack>
      </Container>
    </Page>
  );
}
