// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

// Initial State
const initialState = {
  type: null,
  details: null,
  wb_agent: null,
  broadcastLimit: null,
  whatsAppChatAgents: {},
  whatsAppChatAgentsError: {},
  whatsAppSetupData: {},
  onboardUrl: "",
  webhook: {},
  loader: true,
  isMigrating: "",
  phoneDetails: {},
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const {
      type,
      details,
      broadcastLimit,
      whatsAppSetupData,
      onboardUrl,
      webhook,
      isMigrating,
      phoneDetails,
    } = action.payload;
    return {
      ...state,
      type,
      details,
      broadcastLimit,
      whatsAppSetupData,
      onboardUrl,
      webhook,
      isMigrating,
      phoneDetails,
      loader: false,
    };
  },
  CREATE_WB_AGENT: (state, action) => {
    const { agent } = action.payload;
    return {
      ...state,
      wb_agent: agent,
    };
  },
  GET_WHATSAPPCHAT_AGENT: (state, action) => {
    const { agentSetupData } = action.payload;
    return {
      ...state,
      whatsAppChatAgents: agentSetupData,
    };
  },
  GET_WHATSAPPCHAT_AGENT_ERROR: (state, action) => {
    const { error } = action.payload;
    return {
      ...state,
      whatsAppChatAgentsError: error,
    };
  },
  SET_IS_MIGRATING: (state, action) => {
    const { status } = action.payload;
    return {
      ...state,
      isMigrating: status,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const WhatsAppSettingsContext = createContext();

// Provider Component
export const GupshupSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios.get(
        `/lms_campaign/api/whatsapp/settings`
        // { signal: controller.signal },
      );
      const res = await response.data;

      // cancel the request
      // controller.abort();
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: res?.details && res?.details.type,
          details: res?.details && JSON.parse(res?.details.details),
          broadcastLimit: res?.details && res?.details.broadcast_limit,
          whatsAppSetupData: res?.details && res?.details,
          onboardUrl: res?.onboard_url && res?.onboard_url,
          webhook: res?.webhook,
          isMigrating:
            res?.details && JSON.parse(res?.details.details).is_migration,
          phoneDetails: res?.details && res?.details?.phone,
          loader: false,
        },
      });
      // } else {
      //   dispatch({
      //     type: "INITIALIZE",
      //     payload: {
      //       type: null,
      //       details: null,
      //       broadcastLimit: null,
      //       whatsAppSetupData: {},
      //       onboardUrl: "",
      //       webhook: {},
      //       isMigrating: "",
      //       phoneDetails: {},
      //       loader: false,
      //     },
      //   });
      // }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: null,
          details: null,
          broadcastLimit: null,
          whatsAppSetupData: {},
          onboardUrl: "",
          webhook: {},
          isMigrating: "",
          phoneDetails: {},
          loader: false,
        },
      });
    }
  };

  const addGupshupSettings = async (accSettings) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_campaign/api/whatsapp/settings",
        params: {
          type: accSettings.type,
          app_name: accSettings.appName,
          api_key: accSettings.apiKey,
          sender_no: accSettings.senderNumber,
          broadcast_limit: accSettings.broadcast_limit,
        },
      });
      const { details } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: details.type,
          details: JSON.parse(details.details),
          broadcastLimit: details.broadcast_limit,
          whatsAppSetupData: details,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const addWhatsappSettings = async (accSettings) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_campaign/api/whatsapp/settings",
        params: {
          type: accSettings.type,
          access_token: accSettings.accessToken,
          wb_id: accSettings.wbId,
          broadcast_limit: accSettings.broadcast_limit,
          app_id: accSettings.appId,
        },
      });
      const { details } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: details.type,
          details: JSON.parse(details.details),
          broadcastLimit: details.broadcast_limit,
          whatsAppSetupData: details,
          phoneDetails: details && details?.phone,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const addAiSensySettings = async (accSettings) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_campaign/api/whatsapp/settings",
        params: {
          type: accSettings.type,
          email: accSettings.email,
          password: accSettings.password,
          projectid: accSettings.projectid,
          broadcast_limit: accSettings.broadcast_limit,
        },
      });
      const { details } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: details.type,
          details: JSON.parse(details.details),
          broadcastLimit: details.broadcast_limit,
          whatsAppSetupData: details,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const addEmovurCloudSettings = async (accSettings) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_campaign/api/whatsapp/settings",
        params: {
          type: accSettings.type,
          broadcast_limit: accSettings.broadcast_limit,
        },
      });
      const res = await response.data;
      const { details } = await res;
      dispatch({
        type: "INITIALIZE",
        payload: {
          type: details.type,
          details: JSON.parse(details.details),
          broadcastLimit: details.broadcast_limit,
          whatsAppSetupData: details,
          onboardUrl: res?.onboard_url && res?.onboard_url,
          webhook: res?.webhook,
          isMigrating: details && JSON.parse(details.details).is_migration,
          phoneDetails: details && details?.phone,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const changeUserStatus = async (userID) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_user/api/user_status/${userID}`,
      });
      const { user } = await response.data;
      dispatch({
        type: "CHANGE_USER_STATUS",
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getChatAgents = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_chat/api/chat_agents`,
      });
      const { agents } = await response.data;
      return agents;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const createChatAgent = async (agentId, agentName, takeoverAgent) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: "/lms_chat/api/wb_chat_agent",
        params: {
          chat_agent_id: agentId,
          chat_agent_name: agentName,
          takeover_agent: takeoverAgent,
        },
      });
      const { agent } = await response.data;
      dispatch({
        type: "CREATE_WB_AGENT",
        payload: {
          agent,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const handleMigratingStatus = (status) => {
    dispatch({ type: "SET_IS_MIGRATING", payload: { status } });
  };

  useEffect(() => {
    initialize();
    const getWhatsappAgent = async () => {
      try {
        // const accessToken = localStorage.getItem("accessToken");
        const response = await axios({
          method: "get",
          url: `/lms_chat/api/wb_chat_agent`,
        });
        const agentSetupData = await response.data;
        dispatch({
          type: "GET_WHATSAPPCHAT_AGENT",
          payload: {
            agentSetupData,
          },
        });
      } catch (error) {
        dispatch({
          type: "GET_WHATSAPPCHAT_AGENT_ERROR",
          payload: {
            error,
          },
        });
        throw new Error(JSON.stringify(error));
      }
    };
    getWhatsappAgent();
  }, []);

  return (
    <WhatsAppSettingsContext.Provider
      value={{
        type: state.type,
        details: state.details,
        wb_agent: state.wb_agent,
        broadcastLimit: state.broadcastLimit,
        whatsAppChatAgents: state.whatsAppChatAgents,
        whatsAppChatAgentsError: state.whatsAppChatAgentsError,
        whatsAppSetupData: state.whatsAppSetupData,
        onboardUrl: state.onboardUrl,
        loader: state.loader,
        isMigrating: state.isMigrating,
        webhook: state.webhook,
        phoneDetails: state.phoneDetails,
        addGupshupSettings,
        addWhatsappSettings,
        changeUserStatus,
        getChatAgents,
        createChatAgent,
        addAiSensySettings,
        addEmovurCloudSettings,
        initialize,
        handleMigratingStatus,
      }}
    >
      <Outlet />
      {/* {children} */}
    </WhatsAppSettingsContext.Provider>
  );
};
