// utils
import { USER_GLOBAL_HOST_API_KEY } from "src/config";
import axiosInstance from "src/utils/axios";

const addUserToOrg = async (data, orgId) => {
  try {
    const response = await axiosInstance({
      method: "put",
      url: `${USER_GLOBAL_HOST_API_KEY}/organizations/${orgId}/user`,
      data,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { addUserToOrg };
