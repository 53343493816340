import { createContext, useReducer, useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

// Initial state
const initialState = { fields: null };

// Create context
export const CustomFieldValuesContext = createContext();

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { fields } = action.payload;
    return { ...state, fields };
  },
};

//  Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create provider component
export const CustomFieldValuesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (isValidToken(accessToken)) {
      const response = await axios.get(`lms_user/api/custom_field/values`, {
        // headers: { Authorization: `Bearer ${accessToken}` },
      });
      const { fields } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          fields,
        },
      });
      // }
    } catch (error) {}
  };

  useEffect(() => initialize(), []);

  return (
    <CustomFieldValuesContext.Provider
      value={{ fields: state.fields, dispatch, initializeTable: initialize }}
    >
      <Outlet />
    </CustomFieldValuesContext.Provider>
  );
};
