import PropTypes from "prop-types";
// react
import { createContext, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { USER_GLOBAL_HOST_API_KEY } from "../config";
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const _id = localStorage.getItem("_id");
        // const refreshToken = localStorage.getItem("refreshToken");
        if (_id) {
          const response = await axios.get(
            `${USER_GLOBAL_HOST_API_KEY}/lms_user/api/user-profile`,
            {
              // headers: {
              //   "x-user-id": `${_id}`,
              // },
            }
          );
          const res = response.data;
          const profile = JSON.stringify(res);
          setSession(profile, _id);

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              profile: profile,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              profile: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    // const response = await axios.post("/lms_user/api/account/login", {
    //   email,
    //   password,
    // });
    // const response = await axios.post(
    //   "/lms_user/api/login",
    //   {},
    //   {
    //     params: { email: "admin@gmail.com", password },
    //   }
    // );
    const data = new URLSearchParams();
    data.append("email", email);
    data.append("password", password);
    const response = await axios({
      method: "post",
      url: `${USER_GLOBAL_HOST_API_KEY}/auth/login`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data,
    });
    const { access_token, refresh_token, _id } = await response.data;

    const userProfile = await axios.get(
      `${USER_GLOBAL_HOST_API_KEY}/lms_user/api/user-profile`,
      {
        headers: {
          "x-user-id": `${_id}`,
        },
      }
    );
    const res = userProfile.data;
    const profile = JSON.stringify(res);
    setSession(profile, _id);

    dispatch({
      type: "LOGIN",
      payload: {
        profile,
      },
    });
  };

  // const register = async (email, password, firstName, lastName) => {
  //   const response = await axios.post("lms_user/api/account/register", {
  //     email,
  //     password,
  //     firstName,
  //     lastName,
  //   });
  //   const { accessToken, user } = response.data;

  //   localStorage.setItem("accessToken", accessToken);

  //   dispatch({
  //     type: "REGISTER",
  //     payload: {
  //       user,
  //     },
  //   });
  // };
  const register = async (data, domain) => {
    try {
      const formData = new FormData();
      const request_token = localStorage.getItem("requestToken");
      formData.append("org_name", data.org_name);
      formData.append("user_name", data.user_name);
      formData.append("email", data.email);
      formData.append("mobile", data.mobile);
      formData.append("location", data.location);
      formData.append("about", data.about);
      formData.append("password", data.password);
      formData.append("request_url", domain);
      formData.append("request_token", request_token);

      const response = await axios({
        method: "post",
        url: "/lms_user/api/org/signup",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });
      const res = await response.data;
      return res;
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    const accessToken = localStorage.getItem("accessToken");
    await axios({
      method: "get",
      url: `${USER_GLOBAL_HOST_API_KEY}/auth/logout`,
      // headers: {
      //   Authorization: `Bearer ${accessToken}`,
      // },
    });
    setSession(null, null);
    dispatch({ type: "LOGOUT" });
  };

  const requestOTP = async (mobile) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: `/lms_user/api/forget_password`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { mobile: mobile },
      });
      const { message } = await response.data;
      return message;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const verifyOTP = async (otp) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: `/lms_user/api/verify_forget_password`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { otp: otp },
      });
      const { token } = await response.data;
      return token;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const resetPassword = async (passwordData, token) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "post",
        url: `/lms_user/api/reset_password`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          new_password: passwordData.password,
          confirm_password: passwordData.confirmPassword,
          token: token,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const manageAccount = async (id) => {
    try {
      const userProfile = await axios.get(
        `${USER_GLOBAL_HOST_API_KEY}/lms_user/api/user-profile`,
        {
          headers: {
            "x-user-id": `${id}`,
          },
        }
      );
      const res = userProfile.data;
      const profile = JSON.stringify(res);
      setSession(profile, id);

      dispatch({
        type: "LOGIN",
        payload: {
          profile,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        requestOTP,
        verifyOTP,
        resetPassword,
        manageAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
